import React from 'react';
import { styleMapping } from "../utils/tools";
import MenuElement from "../elements/menuElement";
import * as actions from '../../store/pseudo/general';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify'; 
const options = { autoClose: 1500, type: toast.TYPE.INFO, hideProgressBar: false};


class Menu extends React.Component {

    //############################ CONSTRUCTOR ###################################
	//############################ CONSTRUCTOR ###################################
	constructor(props) {
        super(props);
        this.state={menu: null};
    }  

    async componentDidMount(){
        if(this.props.settingsManual){
            const temp = JSON.parse(this.props.settingsManual);
            this.setState({menu: temp});
        } 
        else {this.setState({menu: this.props.settings.menu});}
    }

    UNSAFE_componentWillReceiveProps(props){
        if(props.settingsManual){            
            const temp = JSON.parse(props.settingsManual);
            this.setState({menu: temp});
        }
    }
    

    copyEmailToClipboard = (email) => {
        navigator.clipboard.writeText(email).then(() => {
            toast.success("Contact email address copied to the clipboard", options)
            // Optionally, you can show a notification or a message to the user
        }).catch(err => {

            console.error('Failed to copy email address: ', err);
        });
    }

    // ############################ RENDER ###################################
    // ############################ RENDER ###################################
    render() {
        let menu;
        const { architecture } = this.props;
        
        if(this.state.menu){ menu = this.state.menu; }
        else { menu = this.props.settings.menu; }
                            
        console.log('##### MENU RENDER => ', architecture);
    
        if(menu){
            return ( 
                <div aria-labelledby="dropdownLogo" className={ "BRL Wide100 " + menu.container.class } style={styleMapping(menu.container)} >
                    <div className="BRL Wide100 InlineBlock">
                        {architecture.menu ? 
                            Object.keys(architecture.menu).map((part, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        {architecture.menu[part]['name'] !== 'business' && architecture.menu[part]['name'] !== 'Contact' &&
                                        <Link key={index} className={'BRL ' + (this.props.custom ? this.props.custom.container : '')} to={"/" + architecture.menu[part].link }>
                                            <MenuElement key={index} class="BRL Hand" index={index} data={architecture.menu[part]} settings={(this.props.custom ? this.props.custom.element : menu.element)}></MenuElement>
                                        </Link>}
    
                                        {architecture.menu[part]['name'] === 'Contact' && 
                                        // <a key={index} className={'BRL ' + (this.props.custom ? this.props.custom.container : '')} href="javascript:void(0);" onClick={() => this.copyEmailToClipboard("contact@strangersapp.cc")}>
                                        <a key={index} className={'BRL ' + (this.props.custom ? this.props.custom.container : '')} href={"mailto:" + "contact@strangersapp.cc"} onClick={() => this.copyEmailToClipboard("contact@strangersapp.cc")}>
                                            <MenuElement key={index} class="BRL Hand" index={index} data={{"name": "contact@strangersapp.cc"}} settings={(this.props.custom ? this.props.custom.element : menu.element)}></MenuElement>
                                        </a>}
                                    </React.Fragment>
                                );
                            })
                        : null
                        }
                    </div>          
                </div>
            );
        }           
    }
}

const mapstateToProps = (state) => {
    return {
        isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, 
        status:state.status, 
        user:state.user, 
        settings:state.settings, 
        architecture:state.architecture
    };
}
const mapDispatchToProps = dispatch => {
    return {
        navigate: (direction, history) => dispatch(actions.navigate(direction, history)), 
        sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))
    }
}
export default withRouter(connect(mapstateToProps, mapDispatchToProps)(Menu));

