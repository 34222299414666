import React, { Component} from "react";
import { Route, Switch, Redirect,withRouter  } from "react-router-dom";
import * as actions from '../store/pseudo/general';
import { connect } from 'react-redux';
import GeoLocation from "./geolocation/geolocation";
import Website from "./design/website";
import Custom from "./core/custom";

class BaseRouter extends Component {
  render() {    
      return (
        <Switch>
          <Route path="/maps" render={ () => {return <GeoLocation settings={this.props.settings} user={this.props.user} />  }} />
          <Route path='/website/:reference' render={(props) => <Website {...props} settings={this.props.settings} />} />
          <Route path='/custom/:reference' render={(props) => <Custom type={'custom'} structure={''} {...props} settings={this.props.settings} />} />

          <Route path="/" render={ () => {return <GeoLocation settings={this.props.settings} user={this.props.user} /> }} />
          <Redirect from="/index.html " exact to="/" />
          <Redirect from="/notFound " exact to="/" />
          <Redirect from="/" exact to="/" />
          <Redirect from="/index"  to="/" />
      </Switch>
      );
  }
}

const mapStateToProps = (state) => { 
  return {
    isAuthenticated: state.status.token !== null && state.status.token !== '' ? true : false, 
    status:state.status, user:state.user, 
    settings:state.settings, 
    architecture:state.architecture
  };
}
const mapDispatchToProps = dispatch => {
  return {
    sideMenuFunction: (bVal) => dispatch(actions.sideMenu(bVal))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BaseRouter));
