
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import Ad from "../business/ad";
import Event from "../business/event";
import { styleMapping } from "../utils/tools";
import * as design from "../../resources/constants/design";
import $ from 'jquery';
// import PaypalExpressBtn from 'react-paypal-express-checkout';
import { formatData } from "../services/geolocation";
import VisualStats from "../elements/visualStats";
import Custom from "../core/custom";
import CheckBoxCustom from "./checkBoxCustom";
const width = $(window).width();
const option = {label:"Accept user terms", isSelected: false, onCheckboxChange: 'onCheckboxChange' };


export default class InfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = { paypalFeedback: {}, data: null };
  }
  
  async componentDidMount(){
    this.setState({bResult: false, data: this.props.data});
  }
  
  UNSAFE_componentWillReceiveProps(props){
    this.setState({bResult: false, data: props.data});
  }
    

  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    const {onClose, type, show, data } = this.props;
    const { details } = this.props;
    let adData = null;
    let size = (width < 981 ? 'lg' : 'md');

    switch(type){
      default:break;
      case 'advertisement': 
        this.config = design.MODAL_PAYMENT; 
        const temp = JSON.parse(JSON.stringify(details));
        adData = formatData(true, temp['features'][0]);
      break;
      case 'geolocation': 
      this.config = design.MODAL_GEOLOCATION; break;
      case 'gdpr': 
      this.config = design.MODAL_GDPR; break;
      case 'dashboard': 
      this.config = design.MODAL_DASHBOARD; break;
      case 'profile': 
      this.config = design.MODAL_PROFILE; break;
      case 'popUp': 
      this.config = design.MODAL_POPUP; break;
    }

    return (
      <Modal centered={type !== 'stats'} backdrop={true} show={show} size={size} className={"modal z7000 " + this.config.background} style={styleMapping(this.config.style.container)} onHide={() => {onClose()}}>
      {this.config.content.title !== '' &&
      <Modal.Header style={styleMapping(this.config.style.header)} className="z200" closeButton>
        <Modal.Title id="modal1" className={"BRL Wide100"}><div className={"" + this.config.style.title.class} style={styleMapping(this.config.style.title)}>{this.config.content.title}</div></Modal.Title>
      </Modal.Header>}

      <Modal.Body className="p-0" style={{maxHeight: '70vh', zIndex:90, overflow:'auto'}}>
        <React.Fragment>          
        
          {/* // ############################ BODY ################################### */}
          {/* // ############################ BODY ################################### */}
          {this.config.content.body !== '' && <div className={'' + this.config.style.body.class} style={styleMapping(this.config.style.body)} >{this.config.content.body}</div>}
     
          {/* // ############################ AVERTISEMENT ################################### */}
          {/* // ############################ AVERTISEMENT ################################### */}
          {!this.state.bResult && type === 'advertisement' && <React.Fragment>
            {this.config.style.paypal.display && <React.Fragment>
              <Event data={adData} style={design.EVENT} type='preview2'></Event>
            </React.Fragment>}
          </React.Fragment>}

          {/* // ############################ POPUP ################################### */}
          {/* // ############################ POPUP ################################### */}
          {type === 'popUp' && <React.Fragment >
              {this.config.content.body !== '' && <div className={'' + this.config.style.body.class} style={styleMapping(this.config.style.body)}>
                {this.config.content.body}
              </div>}
              {this.state.data && <Ad data={this.state.data} />}
          </React.Fragment>}


          {/* // ############################ GDPR ################################### */}
          {/* // ############################ GDPR ################################### */}
          {type === 'gdpr' && 
          <div className={"BRL Wide100 Padding0px BCGray1 z100 Flex FlexCol"} style={{maxHeight: '40vh'}}>
            <div className={"BRL Wide100 Padding10px Flex5 OverflowAuto"} style={{}}>
              <Custom type={'custom'} reference={'q6IMjZXvXZUbTwT3bVFA'} />
            </div>
            <div className={"Flex1 Padding5px BCGray1 z100 "}>
              <div className={"BRL Wide100 Padding0px TopMargin0px CenterAlign "}>
                <CheckBoxCustom  label={option.label} isSelected={option.isSelected} onCheckboxChange={() => {this.setState({bTerms: true})}} key={option.key}/>
              </div>
              <button type="button" disabled={!this.state.bTerms} className="BRL Wide100 btn btn-secondary TopMargin20px" onClick={() => this.props.onAgreed()}>Proceed</button>
            </div>

          </div>}

          {/* // ############################ STATS ################################### */}
          {/* // ############################ STATS ################################### */}
          {type === 'stats' && 
          <div className={"BRL Wide100 z100 Flex FlexCol"} style={{maxHeight: '100vh'}}>
            <VisualStats config={design.CHARTCONFIG} data={this.props.data}/>
          </div>}

          {/* // ############################ FOOTER ################################### */}
          {/* // ############################ FOOTER ################################### */}
          {this.config.style.footer.display && <div className="modal-footer"><button type="button" className="btn btn-secondary" onClick={onClose}>OK</button></div>}
      
        </React.Fragment>
      </Modal.Body>
      
    </Modal>
    );

  }
  
    // ############################ HANDLE EVENTS ###################################
    // ############################ HANDLE EVENTS ###################################
    handleRetry = () => {
      this.setState({bResult: false});
    };
    
    
    // ############################ HANDLE EVENTS ###################################
    // ############################ HANDLE EVENTS ###################################
    onSuccess = e => {
      // console.log('PAYPAL SUCCESS : ', e);    
      e['event'] = 'success';
      this.setState({bResult: true, paypalFeedback: e});
      this.props.onSuccess(e);
    };
    
    onError = e => {
      // console.log('PAYPAL ERROR : ', e);
      e['event'] = 'error';
      this.setState({bResult: true, paypalFeedback: e});
    };
    
    onCancel = e => {
      e['event'] = 'cancel';
      this.setState({bResult: true, paypalFeedback: e});
      // console.log('PAYPAL CANCEL : ', e);
    };
}