import React, { Component } from 'react';
import ListCreator from "../elements/listCreator"
import Viewer from '../elements/viewer';
import { formatDate } from "../utils/tools";
import * as design from "../../resources/constants/design";

const styleList = {
  container: 'BRL Wide100 TopMargin10px  NoBorder ',
  line: 'BRL Wide100 Padding0px NoBorder  btn-light ',
  column: 'BRL Padding10px NoBorder Hand MinWide30',
  label: 'BRL Wide100 LabelFont FS42 Padding0px NoBorder',
  text: 'BRL Wide100 TitleFont FS40 Padding0px NoBorder ',
};
const columns = ['item', 'offer'];
export default class Event extends Component {
  
  constructor(props) {
    super(props);
    this.setEditor = (editor) => { this.editor = editor; };
  }
  
  // ############################ RENDER ###################################
  // ############################ RENDER ###################################
  render() {
    
    const { style, data } = this.props 
    const time = this.formatTime(data);

    return (
      <React.Fragment>
      <div className="BRL Wide100" style={{maxHeight:'600px', overflow:'hidden', display: 'flex', flexDirection: 'column'}} >
       
        <div className="BRL Wide100 TopMargin0px HPadding10px" style={{flex:1}}> 
          <div className={style.title}>{data.title}</div>
          <div className={style.time_container} style={{borderBottom:'2px solid #ad182f', overflow:'auto'}}>
            
            <div className={style.time + " Wide50 FuturaH CenterAlign"}>{formatDate(new Date(data['date']), 'M ddL')}</div>
            <div className={style.time + " Wide20 FuturaH CenterAlign"}>{time['start']}</div>
            <div className={style.time + " Wide10 FuturaH CenterAlign"}>-</div>
            <div className={style.time + " Wide20 FuturaH CenterAlign"}>{time['end']}</div>
          </div>
        </div>
          
        <div className="BRL Wide100 TopMargin0px HPadding10px" style={{flex:4, overflow: 'auto',maxHeight:'400px'}}> 
          {data.images && <div className="BRL Wide100 VPadding10px HPadding20px VIEW">
            <Viewer type="display" styleList={design.VIEWER_AD} id={data.id_business} images={data.images} />
          </div>}
          {data.description.length > 2 && <div className={style.description} style={{borderRadius:5, backgroundColor:'#f0f0f0'}}>{data.description}</div>}
        </div>

        <div className="BRL Wide100 TopMargin0px HPadding10px" style={{flex:1, overflow: 'hidden'}}>
          <ListCreator type={"display"} styleList={styleList} data={data.promotion} columns={columns}></ListCreator>
        </div>
        
      </div>
      </React.Fragment>
      
    );

  }


  // ############################ FORMAT MIL TO NORMAL TIME ############################
  // ############################ FORMAT MIL TO NORMAL TIME ############################
  formatTime(data){

    let result = {};
    result['start'] = data['start'].substring(0,2) + ':' + data['start'].substring(2,data.len);
    result['end'] = data['end'].substring(0,2) + ':' + data['end'].substring(2,data.len);
    
    let event = new Date(data['date']);
    event = event.setHours(event.getHours() + data['end']); 
    const now = new Date().toUTCString();
    const diff = event - now;
    // console.log('FORMATTIME 0 => ', diff);
    
    const diffHours = diff / 3600000;
    // console.log('FORMATTIME 1 => ', diffHours);
    const hours = Math.floor(diffHours);
    const minutes = (diffHours - hours)*60;
    result['remaining'] = {hours: hours, minutes: minutes};
    result['remaining'] = {};

    // console.log('FORMATTIME => ', result);
    return result;
  }
  
}
