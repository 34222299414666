import Axios from 'axios';
import { getLocalObject, getLocalData, belongsTo, dataToChartFormat, base64Converter , truncate, getZone, isInsideZone } from "../../components/utils/tools";
import * as server from "../../resources/constants/api";
import { getProfile } from "./profile";

const addressFields = ['address', 'postcode', 'city', 'country'];

// ############################ GET CURRENT GPS COORDINATES ###################################
// ############################ GET CURRENT GPS COORDINATES ###################################
export async function getCurrentGPSPosition() {
    let options = { enableHighAccuracy: true };
    let now = new Date();
    let promise = (localStorage.getItem('geolocation') ?  JSON.parse(localStorage.getItem('geolocation')) : {time: now});
    let diff = Math.abs(promise.time - now);
    // console.log('GETGPSPOSITION -1 => ', promise.time, ' - ', now, ' => ',diff); 
    
        if(diff > 30000 || diff === 0){ 
            return new Promise(function(resolve, reject){
                // console.log('GETGPSPOSITION 0 NEW PROMISE => ', diff); 
                navigator.geolocation.getCurrentPosition((position) => {
                // navigator.geolocation.watchPosition((position) => {
                    // console.log('GETGPSPOSITION 1 => ', position); 
                    resolve(position)
                }, 
                (err) => {console.log(`ERROR(${err.code}): ${err.message}`);}, options)})
                .then(function(position){
                    let result = {latitude: truncate(position.coords.latitude, 6), longitude: truncate(position.coords.longitude,6), time: position.timestamp};
                    // console.log('GETGPSPOSITION 2 => ', result); 
                    localStorage.setItem('geolocation', JSON.stringify(result));
                    return result;
                });
        } else {
            // console.log('GETGPSPOSITION 3 => ', promise); 
            return promise;
        }
}

// ############################ TIMER BROADCAST ###################################
// ############################ TIMER BROADCAST ###################################
export async function timerBroadcast(id, bBroadcast) {
    
    // const delay = 900000;
//     const delay = 20000;
//     switch(bBroadcast){
//         case true: 
//             BackgroundTimer.stopBackgroundTimer();     
//             BackgroundTimer.runBackgroundTimer(async () => { 
//                 console.log('TIMER BROADCAST => ');
                uniqueBroadcast(id);
//             },delay);                
//         break;
//         case false:
//             BackgroundTimer.stopBackgroundTimer();                
//         break;
//     }
}

// ############################ UNIQUE BROADCAST ###################################
// ############################ UNIQUE BROADCAST ###################################
export async function uniqueBroadcast(id) {
    let data = await prepareBroadcast(id);
    data = {...data, ...{idUser: id}}
    // console.log('UNIQUEBROADCAST : ', id, ' => ', data);
    await setUserPosition(data);    
}

// ############################ PREPARE BROADCAST ###################################
// ############################ PREPARE BROADCAST ###################################
export async function prepareBroadcast(id) {
    const profile = await getProfile(id);
    const coordinates = await getCurrentGPSPosition();
    const data = { coordinates: coordinates, profile: profile};
    return data;
}

//########################### PRE ACTIONS ###################################
//########################### PRE ACTIONS ###################################
export const setUserPosition = async (data) => {
    const token = await getLocalData('token');
    const headers = {'headers': {'authorization': `Token ${token}`}}
    await Axios.post(server.URL_SERVER_DJANGO+'/api-geolocation/geolocation/', data, headers).then(async res => {
    }).catch(err => {})
}

//########################### PRE ACTIONS ###################################
//########################### PRE ACTIONS ###################################
export const setFakeData = async (data) => {
    const token = await getLocalData('token');
    let headers = {'headers': {'authorization': `Token ${token}`}};
    if (token !== ''){
        return Axios.post(server.URL_SERVER_DJANGO+'/api-geolocation/geolocation/fake', data, headers).then(async res => {
            return true;
        }).catch(err => {})
    }
}

//########################### GET GEOJSON FOR HEATMAP ###################################
//########################### GET GEOJSON FOR HEATMAP ###################################
export const getHeatMap = async (data, directory) => {
    
    const coord = [data.coordinates.latitude, data.coordinates.longitude];
    const temp = await getLocalObject({'token':'','idUser': ''});
    let headers = {'headers': {'authorization': `Token ${temp.token}`}}

    if (temp.token === '' || temp.token === null){headers = {};}
    let url = server.URL_SERVER_DJANGO+'/api-geolocation/geolocation/?lat='+data.coordinates.latitude+'&lon='+data.coordinates.longitude+'&zoom='+data.zoom+'&start='+data.range[0]+'&end='+data.range[1];
    url = url + '&method=basic';

    await uniqueBroadcast(temp.idUser);

    return await Axios.get(url, headers).then(async res2 => {
        const res = JSON.parse(JSON.stringify(res2));
        const uniqueEvents = Array.from(new Set(res.data['businesses'].map(a => a.id_event + a['business']['id_business']))).map(id => {
            return res.data['businesses'].find(a => a.id_event + a['business']['id_business'] === id)
        });
        console.log('- geolocation uniqueEvents => ', uniqueEvents);
        res.data['businesses'] = [];

        for(const key in uniqueEvents){
            let temp = uniqueEvents[key];
            console.log('- ', key, ' => ', temp);
            if (temp['action'] === 'deleted'){ 
                console.log('- deleting');
                delete res.data['businesses'][key];
            }
            else {
                // temp.description = decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(temp.description, false))));
                temp.description = decodeURIComponent(base64Converter(temp.description, false));
                temp.business.description = decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(temp.business.description, false))));
                res.data['businesses'][key] = temp;
            }
        }
        
        //ADDING REPERTOIRE FOR AREA
        res.data['directory'] = getSpotsDirectory(directory, coord, data.zoom);
        return res.data;
    }).catch(err => {})
}

//########################### GEOCODE ADDRESS ###################################
//########################### GEOCODE ADDRESS ###################################
export const geoCodeAddress = async (data, coordinates) => {
    const token = server.MAPBOX_TOKEN;
    
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+data+'.json?types=place,country,region,postcode&fuzzyMatch=true&access_token='+token;
    if(coordinates == null){url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+data+'.json?types=place,country,region,postcode&fuzzyMatch=true&access_token='+token;}
    return Axios.get(url).then(async res => {
        let results = res.data.features; 
        return results.map(place => {
            let temp = place['place_name'].split(',');
            if(temp.length > 2){temp.splice(-1);}
            let result = temp.join(",");
            // console.log(result);        
            place['place_name'] = result;
            return place;            
        })
    }).catch(err => {})
}
export const geoCodeAddressStreet = async (data, coordinates) => {
    const token = server.MAPBOX_TOKEN;
    let url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+data+'.json?types=place,address,country,region,postcode&fuzzyMatch=true&access_token='+token;
    if(coordinates == null){url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/'+data+'.json?types=place,address,country,region,postcode&fuzzyMatch=true&access_token='+token;}
    return Axios.get(url).then(async res => {
        let results = res.data.features; 
        return results.map(place => {
            let temp = place['place_name'].split(',');
            if(temp.length > 2){temp.splice(-1);}
            let result = temp.join(",");
            place['place_name'] = result;
            return place;            
        })
    }).catch(err => {})
}


//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
export const jsonToGeoJSON = (data) => {
    
    let output = {
        "type": "FeatureCollection",
        "features": []
    }
    
    for (let zone in data) {
        let temp = { "type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": null } };
        // console.log('ZONE: ', zone, ' => ', data[zone]['position'][1], ', ', data[zone]['position'][0]);
        // const lat = zigZag(data[zone]['position'][0]);
        // const lon = zigZag(data[zone]['position'][1]);
        const lat = data[zone]['position'][0];
        const lon = data[zone]['position'][1];
        temp['properties'] = data[zone];
        temp['geometry']['coordinates'] = [lat,lon];
        // console.log('ADDED: ', temp['geometry']['coordinates']);

        output['features'].push(temp);           
    }
    return output;
}


//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
export const jsonToGeoJSONBusiness = (data) => {
    
    let output = {
        "type": "FeatureCollection",
        "features": []
    }
    
    for (let zone in data) {
        let temp = { "type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": null } };
        // console.log('ZONE: ', zone, ' => ', data[zone]['position'][1], ', ', data[zone]['position'][0]);
        const lat = data[zone]['position'][1];
        const lon = data[zone]['position'][0];
        temp['properties'] = data[zone];
        temp['geometry']['coordinates'] = [lat,lon];
        // console.log('TYPE: ', data[zone]['title'], ' => ', data[zone]['type']);
        switch(data[zone]['icon']){
            default:break;
            case 'discount': temp['properties']['icon'] = 'bar';break;
            case 'event': temp['properties']['icon'] = 'music';break;
        }    
        output['features'].push(temp);           
    }
    return output;
}


//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
//########################### TRANSFORM DJANGO DATA INTO GEOJSON ###################################
export const jsonToGeoJSONDirectory = (data) => {
    
    let output = {
        "type": "FeatureCollection",
        "features": []
    }
    
    for (let key in data) {

        let spot = data[key];
        // console.log('jsonToGeoJSONDirectory => ', spot);
        let temp = { "type": "Feature", "properties": {}, "geometry": { "type": "Point", "coordinates": null } };
        const lat = JSON.parse(spot['coordinates'])[1];
        const lon = JSON.parse(spot['coordinates'])[0];
        temp['properties'] = spot;
        temp['geometry']['coordinates'] = [lat,lon];
        output['features'].push(temp);           
    }
    return output;
}


//########################### RANDOMLY MODIFY USER POSITION ###################################
//########################### RANDOMLY MODIFY USER POSITION ###################################
export const zigZag = (data) => {
    
    const random = Math.random();
    return data + random/10000; 
}


//########################### CALCULATE DISTANCE BETWEEN 2GPS COORDINATES ###################################
//########################### CALCULATE DISTANCE BETWEEN 2GPS COORDINATES ###################################
export const deltaCoordinates = (gps1, gps2) => {

    // console.log('GPS => ', gps1 ,' / ', gps2);

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(gps2.latitude-gps1.latitude);  // deg2rad below
    var dLon = deg2rad(gps2.longitude-gps1.longitude); 
    var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(gps1.latitude)) * Math.cos(deg2rad(gps2.latitude)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2); 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    const d = (R * c) * 1000; // Distance in km
    // console.log('DISTANCE => ', d,' m');
    return d;
}
 
export function deg2rad(deg) {
    return deg * (Math.PI/180)
}


// ############################ FORMAT DATA ###################################
// ############################ FORMAT DATA ###################################
// export const formatData = (data)  => { 
    
//     let results = data.properties;
//     // console.log('FORMAT  POPUP 1 => ', results);        
//     let tempBusiness = {}
//     results.position = data.geometry.coordinates;
//     const fieldBusiness = ['id_business', 'companyname','telephone', 'email', 'description', 'address', 'postcode', 'city', 'country'];
//     for(const key in fieldBusiness){ tempBusiness[fieldBusiness[key]] = data.properties.business[fieldBusiness[key]];}
//     results['business'] = tempBusiness;
//     delete results['action'];
//     return results;
// }


// ############################ FORMAT DATA ###################################
// ############################ FORMAT DATA ###################################
export const formatData = (eventMode, data)  => { 
    let results = {};
    
    if (eventMode === true){
        results = data.properties;
        let tempBusiness = {}
        results.position = data.geometry.coordinates;
        const fieldBusiness = ['id_business', 'companyname','telephone', 'email', 'description', 'address', 'postcode', 'city', 'country'];
        for(const key in fieldBusiness){ 
            tempBusiness[fieldBusiness[key]] = data.properties.business[fieldBusiness[key]];
        }
        results['business'] = tempBusiness;
        delete results['action'];    
    }
    else {
        results['business'] = data.properties;
        results['position'] = [JSON.parse(data.properties['coordinates'])];
        results['business']['description'] = decodeURIComponent(decodeURIComponent(decodeURIComponent(base64Converter(results['business']['description'], false))));
    }
    
    return results;
}


// ############################ GET AREA COORDINATES ###################################
// ############################ GET AREA COORDINATES ###################################
export const getBasisCoordinates = (coordinates)  => { 
    const lat = Math.floor(coordinates['latitude'] * 5 * 10) / 10 / 5;
    const lon = Math.floor(coordinates['longitude'] * 5 * 10) / 10 / 5;
    return {longitude: lon, latitude: lat};
}

// ############################ GET AREA COORDINATES ###################################
// ############################ GET AREA COORDINATES ###################################
export const getAreaCoordinates = (coordinates)  => { 
    let results = [];
    let temp = [];
    const areaOffsets = [0, 0.02];
    for(let i=0;i<areaOffsets.length;i++){
        for(let j=0;j<areaOffsets.length;j++){
            temp.push([coordinates.longitude + areaOffsets[j], coordinates.latitude + areaOffsets[i]]);
        } 
    }     
    results = [temp[0],temp[1],temp[3],temp[2]];
    results = [results];
    return results;
}

// ############################ GET AREA STATS ###################################
// ############################ GET AREA STATS ###################################
export const getAreaStats = async (coordinates)  => { 
    let results = {};
    const data = await getHeatMap({coordinates:coordinates, zoom:0, range:['Live', 'Live']})
    results = await compileAreaStats(data['users']);
    // console.log('GETAREASTATS 2 => ', results);
    return results;
}


// ############################ COMPILE AREA STATS ###################################
// ############################ COMPILE AREA STATS ###################################
export const compileAreaStats = async (data)  => { 
    const fields = ['age', 'gender', 'stereotype', 'political'];
    const crossfields = [{a:'age', b: 'gender'}, {a:'gender', b: 'stereotype'}, {a:'age', b: 'stereotype'}, {a:'age', b: 'political'}, {a:'gender', b: 'political'}, {a:'stereotype', b: 'political'}];
    let results = {'charts':{}, 'general':{}};
    let total = 0;
    


    // EXTRACT DATA GENERAL AND CHARTS
    for(const key in data){ 
        for(const key2 in data[key]){
            let value = data[key][key2]; 
            // CHARTS
            if (belongsTo(key2, fields)){
                if (!results['charts'][key2]){results['charts'][key2]={}; }
                if (results['charts'][key2][value]){results['charts'][key2][value]['total']++; }
                else {results['charts'][key2][value] = {total: 1, percentage: 0};}
            }
        }
        total++;
    }
    results['general']['totalUsers'] = total;
 
    // console.log('COMPILESTATS : EXTRACTED ', total ,' => ', results);    
    // CROSS STATSDATA
    for(const key in data){ 
        for(const key2 in crossfields){ 
            const pair = crossfields[key2]; 
            const a = pair['a']; 
            const b = pair['b']; 
            const c = a + '/' + b; 
            const v = data[key][a] + '/' + data[key][b]; 

            if (!results['charts'][c]){results['charts'][c]={};}
            if (!results['charts'][c][v]){results['charts'][c][v]={total: 1, percentage: 0};}
            else {results['charts'][c][v][total]++;}
        }
    }    

    // PROCESS PERCENTAGES
    for(const key in results['charts']){ 
        for(const key2 in results['charts'][key]){
            // const value = results[key][key2]; 
            // console.log('COMPILESTATS : INTERMEDIATE => ', results[key][key2]['total']);
            results['charts'][key][key2]['percentage'] = results['charts'][key][key2]['total'] / total * 100;
       }
    }
    results['charts'] = dataToChartFormat(results['charts']);
    // console.log('COMPILESTATS : FORMATED => ', results);
    return results;
}


// ############################ GET GPS COORDINATES ###################################
// ############################ GET GPS COORDINATES ###################################
// export const getGPSCoordinates = async (data)  => {
//     let searchText = "";
//     for (const key in addressFields){searchText += data[addressFields[key]] + " ";}
//     if(searchText.length>10){
//         // console.log('GEOCODING 0 => ', searchText);
//         let coordinates = await geoCodeAddress(searchText, null);
//         // console.log('GEOCODING 1 => ', coordinates);
//         coordinates = {'latitude': coordinates[0]['center'][1], 'longitude': coordinates[0]['center'][0]}
//         return JSON.stringify([coordinates['latitude'], coordinates['longitude']]);
    
//     }
// }

// ############################ GETGPS COORDINATES ###################################
// ############################ GETGPS COORDINATES ###################################
export const getGPSCoordinates = async (data)  => {
    let searchText = "";
    for (const key in addressFields){searchText += data[addressFields[key]] + " ";}
    if(searchText.length>10){
        // console.log('GEOCODING 0 => ', searchText);
        let coordinates = await geoCodeAddressStreet(searchText, null);
        // console.log('GEOCODING 1 => ', coordinates);
      
      if(coordinates !== null && coordinates){
            coordinates = {'latitude': coordinates[0]['center'][1], 'longitude': coordinates[0]['center'][0]}
            return JSON.stringify([coordinates['latitude'], coordinates['longitude']]);
        }
    
    }
  }




// ############################ CHECKS IF BUSINESS POSITION IN EXEMPT LIST ###################################
// ############################ CHECKS IF BUSINESS POSITION IN EXEMPT LIST ###################################
export const isPositionExempt = (position, areas)  => {
    
    let bExempt = false, result = false;;

    for (const i in areas){
        const area = areas[i];
        result = isInside(position, area)
        if(bExempt === false){ bExempt = result;} 
    }   
    // console.log('RESULT => ', result);
    return bExempt
}


// ############################ GET REPERTOIRE SPOTS BELONGING TO AREA ###################################
// ############################ GET REPERTOIRE SPOTS BELONGING TO AREA ###################################
export const getSpotsDirectory = (directory, coord, zoom)  => { 
    
    let zone = getZone(coord, zoom);

    let results = [];
    directory.forEach(spot => {
        if (isInsideZone(JSON.parse(spot['coordinates']), zone, spot)){
            results.push(spot);
        }
    })
    return results;
}


// ############################ INSIDE POLYGION ALGO ###################################
// ############################ INSIDE POLYGION ALGO ###################################
function isInside(point, vs) {
    
    // console.log('INSIDE => ', point, ' => ',vs);
    
    var x = point[0], y = point[1];
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) !== (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }

    return inside;
};
